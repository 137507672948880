@import "layout";
@import "toastr";

* {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 12px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

* {
  box-sizing: border-box;
}
