@import "./elements/colors";

.ucanlo-toast {
  margin: 5px 6px;
  padding: 4px;
  background-image: none !important;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  background-color: #bbb !important;
}

.toast-warning {
  border-left: 28px solid $color-error;
  border-right: 28px solid $color-error;
}

.toast-message {
  font-size: 16px;
}
