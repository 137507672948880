.ml {
  margin-left: 10px;
}

.mr {
  margin-left: 10px;
}

.mb {
  margin-bottom: 10px;
}

.mb2 {
  margin-bottom: 20px;
}

.strong {
  font-weight: 500;
}

.gray {
  color: gray;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-color {
  color: #f05b41;

}

.error-background-color {
  background-color: #f05b41;;
}
